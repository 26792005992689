export const runtimeConfig =
    typeof window !== 'undefined'
        ? {
              // client
              REACT_APP_BUILD_ENV: window.variables_env.REACT_APP_BUILD_ENV,
              REACT_APP_IMAGESERVER_URL: window.variables_env.REACT_APP_IMAGESERVER_URL,
              REACT_APP_ASSETS_PATH: window.variables_env.REACT_APP_ASSETS_PATH,
              REACT_APP_SHOWROOM_API_URL: window.variables_env.REACT_APP_SHOWROOM_API_URL,
              REACT_APP_WIDGET_LOADER_API_URL: window.variables_env.REACT_APP_WIDGET_LOADER_API_URL,
              REACT_APP_PAYMENT_CALCULATOR_WIDGET_URL: window.variables_env.REACT_APP_PAYMENT_CALCULATOR_WIDGET_URL,
          }
        : {
              // server
              REACT_APP_BUILD_ENV: process.env.REACT_APP_BUILD_ENV,
              REACT_APP_IMAGESERVER_URL: process.env.REACT_APP_IMAGESERVER_URL,
              REACT_APP_ASSETS_PATH: process.env.REACT_APP_ASSETS_PATH,
              REACT_APP_SHOWROOM_API_URL: process.env.REACT_APP_SHOWROOM_API_URL,
              REACT_APP_WIDGET_LOADER_API_URL: process.env.REACT_APP_WIDGET_LOADER_API_URL,
              REACT_APP_PAYMENT_CALCULATOR_WIDGET_URL: process.env.REACT_APP_PAYMENT_CALCULATOR_WIDGET_URL,
          }
