import { apiUrlEnv, buildUrlWithParameters } from '@sm360/helpers'
import axios from 'axios'

import { axiosErrorHandler } from '../../utils/handle-url.utils'

const SHOWROOM_API_URL = apiUrlEnv('REACT_APP_SHOWROOM_API_URL')

const PAYMENT_CALCULATOR_WIDGET_URL = apiUrlEnv('REACT_APP_PAYMENT_CALCULATOR_WIDGET_URL')

const config = {
    headers: { authorization: `Basic c20zNjA6c20zNjA=` },
}

/**
 * Get Config from XMS
 * @param { String } lang - Language
 * @returns {Promise<{}>}
 */
export const getShowroom360ConfigGeneralApiUrl = async (lang, domain) => {
    let apiResponse = {}
    const apiUrl = `/${lang}/website/api/showroom360/config/general`

    const location = domain || window?.web_domain

    const urlComplete = location ? `${location.concat(apiUrl)}` : apiUrl

    const response = await axios.get(urlComplete, config).catch(axiosErrorHandler)
    if (response) {
        apiResponse = response.data
    }
    return apiResponse
}

/**
 * TODO: Remove this call after getting the combined data from Filter API
 * Get Filter Config from XMS
 * @param { String } lang - Language
 * @returns {Promise<{}>}
 */
export const getShowroom360ConfigListingApiUrl = async (lang, domain) => {
    let apiResponse = {}

    const apiUrl = `/${lang}/website/api/showroom360/config/listing`

    const location = domain || window?.web_domain

    const urlComplete = location ? `${location.concat(apiUrl)}` : apiUrl

    const response = await axios.get(urlComplete, config).catch(axiosErrorHandler)
    if (response) {
        apiResponse = response.data
    }
    return apiResponse
}

/**
 * Get Translations
 * @param { String } lang - Language
 * @returns {Promise<{}>}
 */
export const getTranslationsAPIUrl = async (lang, domain) => {
    const apiUrl = `/${lang}/website/api/config/tags`

    const location = domain || window?.web_domain
    const urlComplete = location ? `${location.concat(apiUrl)}` : apiUrl

    const response = await axios.get(urlComplete, config).catch(axiosErrorHandler)
    return response?.data
}

/**
 * Get Sorting Filters
 * @param { String } lang - Language
 * @returns {Promise<{}>}
 */
export const getSortingFiltersAPIUrl = async (lang, domain) => {
    let apiResponse = {}

    const apiUrl = `/${lang}/website/api/config/sort`

    const location = domain || window?.web_domain
    const urlComplete = location ? `${location.concat(apiUrl)}` : apiUrl

    const response = await axios.get(urlComplete, config).catch(axiosErrorHandler)
    if (response) {
        apiResponse = response.data || { sortConfigurations: [] }
    }
    return apiResponse
}

/**
 * Get SEO content
 * @param {*} lang - Selected Language
 * @returns The SEO Content
 */
export const getSeoContentAPIUrl = async (lang, category = 'page-inventory-listing', domain) => {
    let apiResponse = {}

    const apiUrl = `/${lang}/website/api/config/seo?category=${category}`

    const location = domain || window?.web_domain

    const urlComplete = location ? `${location.concat(apiUrl)}` : apiUrl

    const response = await axios.get(urlComplete, config).catch(axiosErrorHandler)
    if (response) {
        apiResponse = response.data || []
    }
    return apiResponse
}

/**
 * Get CTAs content
 * @param {string} lang - Selected Language
 * @param {string} contextName - Context Name (listing, vdp, summary)
 * @param {string} statusName - Status Name (used, new)
 * @returns The CTAs Content
 */
export const getCtasContentAPIUrl = async (lang, contextName, statusName, domain) => {
    const params = []

    let apiResponse = {}

    if (contextName) params.push(`contextName=${contextName}`)
    if (statusName) params.push(`statusName=${statusName}`)

    const queryString = params.length > 0 ? `?${params.join('&')}` : ''
    const apiUrl = `/${lang}/website/api/config/cta`.concat(queryString)

    const location = domain || window?.web_domain

    const urlComplete = location ? `${location.concat(apiUrl)}` : apiUrl
    const response = await axios.get(urlComplete, config).catch(axiosErrorHandler)
    if (response) {
        apiResponse = response.data
    }
    return apiResponse
}
/**
 * Get Tagging configurations
 * @param {*} lang - Selected Language
 * @returns The Tagging configurations
 */
export const getTaggingConfigAPIUrl = async (lang, domain) => {
    let apiResponse = {}

    const apiUrl = `/${lang}/website/api/config/tagging`

    const location = domain || window?.web_domain

    const urlComplete = location ? `${location.concat(apiUrl)}` : apiUrl

    const response = await axios.get(urlComplete, config).catch(axiosErrorHandler)
    if (response) {
        apiResponse = response.data || { taggingConfigurations: [] }
    }
    return apiResponse
}

export const getJwt = async () => {
    return await axios.get(`${SHOWROOM_API_URL}/service/v1/jwt?userName=public&password=public`)
}

export const saveQuote = (saveQuoteDetails, config, lang) => {
    const organizationId = config?.orgId
    const organizationUnitId = config?.orgUnitId
    const basePath = `${SHOWROOM_API_URL}/service/v1`
    const context = `orgId=${organizationId}&orgUnitId=${organizationUnitId}` + `&lang=${lang}&priceIncreaseRollCount=0&module=2`

    const url =
        saveQuoteDetails.showroomVehicleId && saveQuoteDetails.vehicleCondition.indexOf('new') === 0
            ? `${basePath}/quote/vehicle/listingNew/${saveQuoteDetails.showroomVehicleId}?${context}`
            : `${basePath}/quote/vehicle/listingUsed?${context}`

    return new Promise((resolve) => {
        return getJwt().then((response) => resolve(response.data))
    }).then((token) => {
        return axios
            .put(url, saveQuoteDetails, {
                headers: {
                    'x-jwt': token,
                },
            })
            .then((result) => {
                const quoteId = result.data.id
                return Promise.resolve(quoteId)
            })
    })
}

export const getCalculatorApiUrl = async (lang = 'en', vehicleId, dealerInfos, domain) => {
    const { orgId, orgUnitId, orgUnitProvince } = dealerInfos

    const baseUrl = `${PAYMENT_CALCULATOR_WIDGET_URL}/payment-calculator-360/render/modalbeta?`

    const paramsUrl = {
        location: orgUnitProvince,
        orgId,
        orgUnitId,
        lang,
        vehicleId,
        category: 'inventory',
        domain: domain || window?.web_domain,
        isStandaloneVLP: true,
    }

    const apiUrl = buildUrlWithParameters(baseUrl, paramsUrl)
    let data = {}
    try {
        const response = await axios.get(apiUrl)
        data = await response.data
    } catch (error) {
        console.log(error)
    }
    return data
}
