import React from 'react'
import VehiclesSkeleton from '../vehicles/Vehicles.skeleton'

const ListingAlphaSkeleton = () => {
    return (
        <div className='il-listingAlpha'>
            <div className='il-listingAlpha__top'>
                <div className='il-listingAlpha__breadcrumb -wrapper'>
                    <div className='il-breadcrumb il-listingAlpha__breadcrumb'>
                        <div className='skeleton skeleton-text'></div>
                    </div>
                </div>
                <div className='il-listingAlpha__promoBanner il-promoBanner'>
                    <div className='skeleton skeleton-image'></div>
                </div>
                <div className='il-listingAlpha__heading -wrapper'>
                    <div className='skeleton skeleton-text'></div>
                </div>
            </div>
            <div className='il-listingAlpha__container -wrapper'>
                <div className='il-listingAlpha__sidebar'>
                    <div className='skeleton skeleton-text'></div>
                    <div className='skeleton skeleton-text'></div>
                    <div className='skeleton skeleton-text'></div>
                    <div className='skeleton skeleton-text'></div>
                    <div className='skeleton skeleton-text'></div>
                    <div className='skeleton skeleton-text'></div>
                    <div className='skeleton skeleton-text'></div>
                    <div className='skeleton skeleton-text'></div>
                    <div className='skeleton skeleton-text'></div>
                </div>
                <div className='il-listingAlpha__mainContent'>
                    <div className='il-listingAlpha__vehicleSortSection'>
                        <div className='il-listingAlpha__totalVehicles'>
                            <div className='skeleton skeleton-text'></div>
                        </div>
                        <div className='il-listingAlpha__vehicleAndPageSorting'>
                            <div className='il-listingAlpha__vehicleSorting'>
                                <div className='skeleton skeleton-text'></div>
                            </div>
                            <div className='il-pageSorting'>
                                <div className='skeleton skeleton-text'></div>
                            </div>
                        </div>
                    </div>
                    <div className='il-listingAlpha__vehicles'>
                        <VehiclesSkeleton />
                    </div>
                    <div className='il-listingAlpha__pagination'>
                        <div className='skeleton skeleton-text'></div>
                    </div>
                </div>
            </div>
            <div className='il-listingAlpha__footer'>
                <div className='il-listingAlpha__footerContent'>
                    <div className='skeleton skeleton-text'></div>
                </div>
            </div>
        </div>
    )
}

export default ListingAlphaSkeleton
